/** @jsx jsx */
import { useState } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { jsx } from "theme-ui"
import { Styled } from "theme-ui"
import { AiOutlineRight } from "react-icons/ai"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MultilineText from "../components/multiline-text"
import Section from "../components/section"
import Container from "../components/container"
import SectionFilmPrix from "../components/section-film-prix"
import SectionFilmVideos from "../components/section-film-videos"
import SectionFilmPhotos from "../components/section-film-photos"
import SectionFilmFichetech from "../components/section-film-fichetech"
import ActualiteItem from "../components/actualite-item"
import ActualiteCollection from "../components/actualite-collection"
import playIcon from "../images/play.svg"
import LightboxVideo from "../components/lightbox-video"

const MainBox = props => (
  <div
    {...props}
    sx={{
      display: ["block", "flex"],
      flexDirection: "row",
      margin: "0 auto",
      width: ["100%", "85%", "70%"],
    }}
  />
)

const TitleBox = props => (
  <div
    {...props}
    sx={{
      width: ["100%", "100%"],
    }}
  />
)

export default function Template({ data, location }) {
  const [lightboxVisible, setLightboxVisible] = useState(false)
  const film = data.bubbleCreation
  const actualites = film.fields.actualites

  let isGrey = false
  return (
    <Layout>
      <SEO
        title={film.Titre_FR ? film.Titre_FR : ""}
        description={film.Synopsis_FR ? film.Synopsis_FR : ""}
        creation={film}
        location={location}
      />

      <div
        sx={{
          position: "relative",
        }}
      >
        {film.Affiche_optimized &&
          film.Affiche_optimized.childImageSharp && (
            <Img
              sx={{
                width: "100%",
                height: ["40vh", "calc(80vh - 60px)"],
                maxHeight: "900px",
              }}
              fadeIn={true}
              durationFadeIn={300}
              fluid={film.Affiche_optimized.childImageSharp.fluid}
              placeholderStyle={{ opacity: 0.1, filter: "blur(10px)" }}
            />
          )}
        <div
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LightboxVideo
            url={film.Video_principale_URL}
            lightboxVisible={lightboxVisible}
            setLightboxVisible={setLightboxVisible}
          />
          <div onClick={() => setLightboxVisible(true)}>
            <img
              sx={{
                width: ["80px", "120px"],
                opacity: 0.6,
                cursor: "pointer",
                ":hover": {
                  opacity: 0.8,
                },
              }}
              src={playIcon}
              alt="playIcon"
              placeholderStyle={{ opacity: 0.1, filter: "blur(10px)" }}
            />
          </div>
        </div>
      </div>

      <Section isGrey={false}>
        <Container isFull={false} isText={true}>
          <MainBox>
            <TitleBox>
              <Styled.h1>{film.Titre_FR}</Styled.h1>
              <Styled.p sx={{ my: 3, fontWeight: 400 }}>
                {film.Realisateurs.map((realisateur, i) => (
                  <Styled.strong key={i}>
                    <Link
                      to={`/personne/${realisateur.slug}`}
                      sx={{
                        textDecoration: "none",
                        color: "#000",
                        display: "inline-block",
                      }}
                    >
                      <div
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {i > 0 ? (
                          <span sx={{ ml: 2 }}>
                            {realisateur.CACHE_Nom_complet}
                          </span>
                        ) : (
                          realisateur.CACHE_Nom_complet
                        )}
                        <AiOutlineRight
                          color={"#CCC"}
                          size={"0.9em"}
                          sx={{ ml: 1 }}
                        />
                      </div>
                    </Link>
                  </Styled.strong>
                ))}
              </Styled.p>{" "}
              <MultilineText text={film.Synopsis_FR} />
              <div sx={{ my: 3 }}>
                <Styled.p sx={{ my: 0 }}>
                  <Styled.strong>Sortie : </Styled.strong>
                  {new Date(film.Sortie).getFullYear()}
                </Styled.p>
                <Styled.p sx={{ my: 0 }}>
                  <Styled.strong>Durée : </Styled.strong>
                  {film.Duree}
                </Styled.p>
                {film.Cast && (
                  <Styled.p sx={{ my: 0 }}>
                    <Styled.strong>Cast : </Styled.strong> {film.Cast}
                  </Styled.p>
                )}
              </div>
            </TitleBox>
          </MainBox>
        </Container>
      </Section>

      {// check if there are videos in the media array
      film.Medias &&
        film.Medias.some(
          media => media.Type === "VIDEO" && media.Video_URL
        ) && <SectionFilmVideos isGrey={(isGrey = !isGrey)} film={film} />}

      {// check if there are photos in the media array
      film.Medias && film.Medias.some(media => media.Type === "PHOTO") && (
        <SectionFilmPhotos isGrey={(isGrey = !isGrey)} film={film} />
      )}

      {film.Nominations_prix && (
        <SectionFilmPrix isGrey={(isGrey = !isGrey)} film={film} />
      )}

      {film.Fiche_technique && (
        <SectionFilmFichetech isGrey={(isGrey = !isGrey)} film={film} />
      )}

      {actualites && actualites.some(actualite => !actualite.DELETED) && (
        <Section isGrey={(isGrey = !isGrey)}>
          <Container isFull={false}>
            <Section.Title1 sx={{ marginBottom: "22px" }}>
              ACTUALITÉ
            </Section.Title1>
            <ActualiteCollection>
              {actualites.map(
                actualite =>
                  actualite.Image_principale_optimized && (
                    <ActualiteItem
                      isFluid={true}
                      key={actualite.id}
                      actualite={actualite}
                    />
                  )
              )}
            </ActualiteCollection>
          </Container>
        </Section>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    bubbleCreation(id: { eq: $id }) {
      id
      Titre_FR
      CACHE_Realisateurs
      Cast
      Duree
      Fiche_technique
      Nominations_prix
      Sortie
      Synopsis_FR
      Video_principale_URL
      slug
      isArtNum

      Realisateurs {
        CACHE_Nom_complet
        slug
        Image_principale_optimized {
          childImageSharp {
            fluid(maxWidth: 170, maxHeight: 170, quality: 85) {
              # ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluid
            }
          }
        }
      }

      Affiche_optimized {
        childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 700, quality: 85, cropFocus: CENTER) {
            # ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluid
          }
        }
      }

      Image_principale_optimized {
        childImageSharp {
          fluid(
            maxWidth: 1600
            maxHeight: 730
            quality: 85
            cropFocus: CENTER
          ) {
            # ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluid
          }
        }
      }

      fields {
        actualites {
          Description_FR
          Titre_FR
          PUBLIE
          Lien_URL
          Featured
          DELETED
          id
          Image_principale_optimized {
            childImageSharp {
              fluid(
                maxWidth: 370
                maxHeight: 370
                quality: 85
                cropFocus: CENTER
              ) {
                # ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }

      Medias: Medias {
        Titre
        Type
        Video_URL
        Video_type
        id
        Nom_du_fichier
        Fichier
        Image
        DELETED
        Image_meta {
          h
          w
        }
      }
    }
  }
`
